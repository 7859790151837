<h1>{{ 'NAVIGATION.DEVICES' | translate }}</h1>
<h2>
  {{ 'DEVICE.ALL_DEVICES' | translate }}
  <div class="button-box">
    <button (click)="toggleView()" class="button-primary no-text">
      <i [ngClass]="!showGrid ? 'fas fa-th' : 'fas fa-list-ul'"></i>
    </button>
    <button *ngIf="!authService.permissions.SERVICE" (click)="pairDevice()" class="button-primary">
      <i class="fa fa-plus"></i> {{ 'DEVICE.NEW_DEVICE' | translate }}
    </button>
  </div>
</h2>

<app-device-filters   
[datasource]="dataSource"
></app-device-filters>

<div id="devices-content" class="devices-content">

  <div id="grid" *ngIf="showGrid">
    <div class="row">
      <ng-container *ngFor="let device of dataSource.currentItems">
        <div class="col-12 col-lg-3 mb-4">
          <app-device-card
            class="device-card"
            [device]="device"
            [kcUser]="kcUser"
            (update)="loadDataSource()"
          ></app-device-card>
        </div>
      </ng-container>
    </div>
    <ngb-pagination
      *ngIf="(dataSource.totalItems || 0) > dataSource.limit"
      [pageSize]="dataSource.limit"
      [page]="dataSource.page + 1"
      (pageChange)="dataSource.setPage($event - 1)"
      [collectionSize]="dataSource.totalItems || 0"
      [maxSize]="5"
    ></ngb-pagination>
  </div>

  <div id="table" *ngIf="!showGrid">
    <app-datatable-paginated
      [dataSource]="dataSource"
      [columns]="columns"
      [scrollable]="false"
      [className]="'fix-height'"
    ></app-datatable-paginated>
  </div>

</div>

<ng-template #deviceInfoTpl let-row="row">
  <div class="my-2">
    <div class="device-info">
      <app-device-type [device]="row"></app-device-type>
      <app-online-device [device]="row"></app-online-device>
    </div>
    <img [src]="getImage(row)" *ngIf="showDeviceInfo(infoDeviceEnum.icon)"
      class="device-img" alt="Device type image">
  </div>
</ng-template>

<ng-template #displayTpl let-row="row">
  <ng-container *ngIf="row.owner === kcUser.id">
    <ul class="display-list">  
      <li *ngFor="let display of row.displays">  
        <span>{{display.name}}</span>  
      </li> 
    </ul>
  </ng-container>
</ng-template>

<ng-template #lastSyncTpl let-row="row">
    <span *ngIf="row.lastSync">
      {{ row.lastSync | fromUTCToLocale | date: 'medium':timezone:lang }}
    </span>
    <span *ngIf="!row.lastSync">--</span>
</ng-template>

<ng-template #firstAssociationTpl let-row="row">
    <ng-container *ngIf="row.currentOwnerFirstAssociation">
      <span [ngClass]="{ hidden: !(row.owner === kcUser.id) }">
        {{ row.currentOwnerFirstAssociation | fromUTCToLocale | date: 'medium':timezone:lang }}
      </span>
    </ng-container>
    <ng-container *ngIf="!row.currentOwnerFirstAssociation">
      <span [ngClass]="{ hidden: !(row.owner === kcUser.id) }">--</span>
    </ng-container>
</ng-template>

<ng-template #actionTpl let-row="row">
  <app-device-action
    [device]="row"
    [kcUser]="kcUser"
    (update)="loadDataSource()"
  ></app-device-action>
</ng-template>



