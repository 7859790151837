import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ODataFiltrableDataSource } from 'filtrable-data-source';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DeviceBoDtoNBK, DeviceService, UserKeycloakDtoNBK } from '../api/nbk';
import { getTimeZone, getUrlRedirectPopupDevice, KEY_LANGUAGE_STORAGE } from '../core/utils';
import { PairDeviceComponent } from '../shared/modals/pair-device/pair-device.component';
import { ActivatedRoute } from '@angular/router';
import { getDeviceImage, InfoDeviceEnum, IPairDevice, showDeviceInfo } from '../core/device.utils';
import { TableColumn } from '@swimlane/ngx-datatable';
import { AuthService } from '../core/auth.service';

type DeviceTableColumn = TableColumn & {
  infoDeviceEnum?: InfoDeviceEnum
}

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  kcUser: UserKeycloakDtoNBK;
  dataSource = new ODataFiltrableDataSource<DeviceBoDtoNBK>();

  infoDeviceEnum = InfoDeviceEnum;

  showGrid: boolean = true;
  columns: DeviceTableColumn[] = [];

  @ViewChild('deviceInfoTpl', { static: true }) 
  deviceInfoTpl: ElementRef<HTMLElement>;

  @ViewChild('displayTpl', { static: true }) 
  displayTpl: ElementRef<HTMLElement>;

  @ViewChild('lastSyncTpl', { static: true }) 
  lastSyncTpl: ElementRef<HTMLElement>;

  @ViewChild('firstAssociationTpl', { static: true }) 
  firstAssociationTpl: ElementRef<HTMLElement>;
  
  @ViewChild('actionTpl', { static: true }) 
  actionTpl: ElementRef<HTMLElement>;

  loadDevices$ = new Subject<void>();
  unsubscribe$ = new Subject<void>();

  lang: string;
  timezone: string;

  constructor(
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    private modalService: NgbModal,
    private ts: TranslateService,
    private toastrService: ToastrService,
    public authService: AuthService,
  ) {
    this.lang = localStorage.getItem(KEY_LANGUAGE_STORAGE)!;
    this.timezone = getTimeZone();

    this.kcUser = this.route.snapshot.data.deviceData.loggedUser;

    this.dataSource.fetchFunction = (top, skip, count, _orderBy, filter, search) => {
      return this.authService.permissions.SERVICE 
      ? this.deviceService.getAllDevicesForServiceUser(
        localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
        top,
        skip,
        count,
        _orderBy,
        filter,
        search
      )
      : this.deviceService.getAllDevicesWIthDisplays(
          localStorage.getItem(KEY_LANGUAGE_STORAGE)!,
          top,
          skip,
          count,
          _orderBy,
          filter,
          search
        );
    };

    this.dataSource.limit = 8;
  }

  ngOnInit(): void {
    this.columns = [
      {
        name: '',
        sortable: false,
        resizeable: false,
        maxWidth:100,
        cellTemplate: this.deviceInfoTpl
      },
      {
        prop: 'name',
        name: this.ts.instant('DEVICES.DEVICE_CARD.NAME'),
        $$valueGetter: (e, prop) => e.name || '--' ,
        infoDeviceEnum: InfoDeviceEnum.name
      },
      {
        prop: 'hardwareId',
        name: this.ts.instant('DEVICES.DEVICE_CARD.HARDWARE_ID'),
        $$valueGetter: (e, prop) => e.hardwareId || '--' ,
        infoDeviceEnum: InfoDeviceEnum.hardware
      },
      {
        prop: 'serial',
        name: this.ts.instant('DEVICES.DEVICE_CARD.SERIAL_NUMBER'),
        $$valueGetter: (e, prop) => e.serial || '--' ,
        infoDeviceEnum: InfoDeviceEnum.serial
      },
      {
        prop: 'firmware',
        name: this.ts.instant('DEVICES.DEVICE_CARD.FIRMWARE'),
        sortable: false,
        $$valueGetter: (e, prop) => e.firmware || '--' ,
        infoDeviceEnum: InfoDeviceEnum.firmware
      },
      {
        prop: 'model',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODEL'),
        $$valueGetter: (e, prop) => e.model || '--' ,
        infoDeviceEnum: InfoDeviceEnum.model
      },
      {
        prop: 'displays',
        name: this.ts.instant('MY_DISPLAY.DISPLAY'),
        sortable: false,
        cellTemplate: this.displayTpl,
        infoDeviceEnum: InfoDeviceEnum.display
      },
      {
        prop: 'lastSync',
        name: this.ts.instant('DEVICES.DEVICE_CARD.SINC'),
        cellTemplate: this.lastSyncTpl,
        infoDeviceEnum: InfoDeviceEnum.sync
      },
      {
        prop: 'currentOwnerFirstAssociation',
        name: this.ts.instant('DEVICES.DEVICE_CARD.REGISTRATION_DATE'),
        sortable: false,
        cellTemplate: this.firstAssociationTpl,
        infoDeviceEnum: InfoDeviceEnum.date
      },
      {
        prop: '',
        name: '',
        sortable: false,
        resizeable: false,
        maxWidth:50,
        cellTemplate: this.actionTpl
      },
    ];

    this.hideColumn();

    this.loadDevices$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => this.dataSource.loadData())
      )
      .subscribe();

  }

  ngOnDestroy() {
    this.unsubscribe$.complete();
    this.unsubscribe$.next();
  }

  getImage (device: DeviceBoDtoNBK) {
    return getDeviceImage(device);
  }

  hideColumn() {
    this.columns = this.columns.filter(column => column.infoDeviceEnum ? this.showDeviceInfo(column.infoDeviceEnum) : true);
  }

  showDeviceInfo(info: InfoDeviceEnum): unknown {
    return showDeviceInfo(info);
  }

  loadDataSource() {
    this.loadDevices$.next();
  }

  toggleView() {
    this.showGrid = !this.showGrid;
  }

  pairDevice(): void {
    const modalRef = this.modalService.open(PairDeviceComponent, {
      backdrop: 'static'
    });

    modalRef.componentInstance.generateSecureCode
      .pipe(
        take(1),
        switchMap((serialNumber: string) =>
          this.deviceService.generateSecureCode(serialNumber)
        ),
        tap(() => (modalRef.componentInstance.modalWizard = 'SECURE_CODE'))
      )
      .subscribe();

    modalRef.componentInstance.pairDevice
      .pipe(
        take(1),
        switchMap((device: IPairDevice) =>
          this.deviceService.addCurrentUserToDevice(
            device.serialNumber,
            device.secureCode
          )
        ),
        tap(() => {
          this.toastrService.success(
            this.ts.instant('DEVICE.DEVICE_PAIRED'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        }),
        finalize(() => {
          modalRef.close();
          this.loadDataSource();

          setTimeout(() => {
            const {
              keycloack: { baseUrl, realm },
              origin
            } = environment;
            const url = getUrlRedirectPopupDevice(baseUrl, realm, origin);
            window.open(url);
          }, 500);
        })
      )
      .subscribe();
  }

}
